<template>
  <b-modal v-model="isModalOpen" :can-cancel="false" :width="700">
    <div class="af-modal-card">
      <div class="modal-card__content">
        <p class="has-text-weight-bold is-size-4 mb-4">
          {{
            CALL_ME_BACK_TYPE.OFFLINE_OFFER === formData.type
              ? $t("af:call_me_back.modal.offline.title")
              : $t("af:call_me_back.modal.main.title")
          }}
        </p>
        <p>
          {{
            CALL_ME_BACK_TYPE.OFFLINE_OFFER === formData.type
              ? $t("af:call_me_back.modal.offline.subtitle")
              : $t("af:call_me_back.modal.main.subtitle")
          }}
        </p>
      </div>

      <form @submit.prevent="send" class="mt-6">
        <validation-observer ref="observer">
          <b-field grouped class="mt-4">
            <BInputWithValidation
              v-model="formData.lastName"
              rules="required"
              :label="$t('af:call_me_back.modal.input.last_name.label')"
              label-position="on-border"
              class="mr-3 is-half"
              :allow-space="true"
            />
            <BInputWithValidation
              v-model="formData.firstName"
              rules="required"
              :label="$t('af:call_me_back.modal.input.first_name.label')"
              label-position="on-border"
              class="is-half"
              :allow-space="true"
            />
          </b-field>

          <phone-input
            :label="$t('af:call_me_back.modal.input.phone.label')"
            v-model="formData.phone"
          />

          <BInputWithValidation
            v-model="formData.email"
            rules="required|email"
            :label="$t('af:call_me_back.modal.input.email.label')"
            label-position="on-border"
            field-class="mb-5"
          />

          <b-field grouped class="mb-5">
            <b-field
              :label="$t('af:call_me_back.modal.input.day.label')"
              label-position="on-border"
              expanded
            >
              <b-datepicker
                v-model="formData.preferredDate"
                locale="hu-HU"
                icon-pack="fa"
                icon="calendar"
                trap-focus
                position="is-top-right"
                :mobile-native="false"
                :min-date="yesterday"
                :years-range="[0, 1]"
              />
            </b-field>

            <b-field
              :label="$t('af:call_me_back.modal.input.time.label')"
              label-position="on-border"
              class="is-half"
            >
              <BSelectWithValidation
                class="time-select"
                v-model="formData.preferredTime"
                expanded
                :placeholder="$t('af:call_me_back.modal.input.time.label')"
                rules="required"
              >
                <option
                  v-for="time in selectableTimes"
                  :value="time"
                  :key="time"
                  :disabled="
                    formData.preferredDate.toDateString() ===
                      new Date().toDateString() &&
                    new Date().getHours() + 1 > parseInt(time.split(':')[0])
                  "
                >
                  {{ time }}
                </option>
              </BSelectWithValidation>
            </b-field>
          </b-field>

          <b-field
            :label="$t('af:call_me_back.modal.input.user_comment.label')"
            label-position="on-border"
            class="mb-2"
          >
            <b-input
              maxlength="2000"
              type="textarea"
              v-model="formData.userComment"
              custom-class="call-me-back-textarea"
            />
          </b-field>

          <statements-acceptance
            :statements="statements"
            layout-type="CALL_ME_BACK"
            read-required-validation-message="af:call_me_back.modal.acceptance.message.validation.statement_read_required"
            ref="statementAcceptances"
            :is-public="true"
          />

          <p class="has-text-weight-bold mt-5">
            <b-button
              type="is-blue"
              :value="$t('af:call_me_back.modal.button.send')"
              tag="input"
              @click="send"
            ></b-button>
            <b-button
              :value="$t('af:call_me_back.modal.button.cancel')"
              tag="input"
              class="ml-4"
              type="is-text"
              @click="cancelModal"
            ></b-button>
          </p>
        </validation-observer>
      </form>

      <div class="close-btn cursor-pointer" @click="cancelModal">
        <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import eventBus, { EVENTS } from "@/utils/eventBus";
import { CALL_ME_BACK_TYPE } from "@/utils/const";
import { mapGetters } from "vuex";
import i18n from "../plugins/i18n";
import BSelectWithValidation from "@/components/BSelectWithValidation.vue";

export default {
  name: "CallMeBackModal",
  components: {
    BSelectWithValidation,
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
    StatementsAcceptance: () => import("@/components/StatementsAcceptance.vue"),
    PhoneInput: () => import("@/components/PhoneInput.vue"),
  },
  props: {},
  data() {
    return {
      CALL_ME_BACK_TYPE,
      isModalOpen: false,
      formData: {
        lastName: null,
        firstName: null,
        phone: "",
        email: null,
        preferredDate: null,
        preferredTime: null,
        type: null,
        origin: null,
        userComment: null,
      },
      selectableTimes: null,
      statements: null,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "session/isReady",
    }),
    yesterday() {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return today;
    },
  },
  async mounted() {
    await this.$store.dispatch("callMeBack/acceptanceStatements");
    this.statements = this.$store.getters["callMeBack/acceptances"];

    this.formData.origin = window.location.pathname;

    if (this.loggedIn) {
      await this.$store.dispatch("profile/fetch");
      let customer = this.$store.getters["profile/get"];
      if (customer.lastName) {
        this.formData.lastName = customer.lastName;
        this.formData.firstName = customer.firstName;
      }

      if (customer.phone) {
        this.formData.phone = customer.phone;
      }

      if (customer.email) {
        this.formData.email = customer.email;
      }
    }

    eventBus.$on(EVENTS.OPEN_CALL_ME_BACK_MODAL, this.openModal);
  },
  methods: {
    openModal(type, offerId) {
      this.generateSelectableTimes();
      this.formData.type = type;
      this.formData.preferredDate = new Date();
      this.formData.preferredDate.setHours(0, 0, 0, 0);
      // this.formData.preferredTime = this.selectableTimes[0];
      this.formData.offerId = offerId;
      this.isModalOpen = true;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
    generateSelectableTimes() {
      this.selectableTimes = [];

      let hour = 9;
      let minute = 0;
      for (let i = 0; i < 17; ++i) {
        this.selectableTimes.push(
          String(hour).padStart(2, "0") + ":" + String(minute).padStart(2, "0")
        );

        if (i % 2 === 0) {
          minute = 30;
        } else {
          minute = 0;
          hour += 1;
        }
      }
    },
    async send() {
      let valid = await this.$refs.observer.validate();
      let statementsValid = await this.$refs.statementAcceptances.validate();

      if (!valid || !statementsValid) return;

      this.formData.preferredDate.setDate(
        this.formData.preferredDate.getDate() + 1
      );

      try {
        if (this.loggedIn) {
          await this.$store.dispatch("callMeBack/create", this.formData);
        } else {
          await this.$store.dispatch("callMeBack/createPublic", this.formData);
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t("af:call_me_back.modal.message.send_success"),
          position: "is-bottom",
          type: "is-success",
        });
        this.cancelModal();
      } catch (ex) {
        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t("af:call_me_back.modal.message.send_failed"),
          position: "is-bottom",
          type: "is-danger",
        });
        console.error(ex);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.is-half {
  width: 50%;
}

.button {
  padding-left: 3rem;
  padding-right: 3rem;
}

.button.is-text {
  text-decoration: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.button.is-text {
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    text-decoration: underline;
    background-color: white;
  }
}
</style>

<style lang="scss">
.time-select {
  select {
    border-radius: 8px !important;
  }
}

.call-me-back-textarea {
  min-height: 6rem !important;
}
</style>
